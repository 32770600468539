<template>
  <div>
    <section v-if="watchlistContent.length>0">
      <!--slider-buttons-div-->
      <div class="slider-header">
        <div class="slider-title">
          <span class="category-type">{{sliderTitle}}S</span>
          <span class="see-all" v-if="watchContents.length > 15">See All</span>
        </div>
        <div class="slider-head">
          <div class="slider-head-btns">
            <div :id="`watchlist-controls-${sliderTitle}`" class="slider-controls">
              <!-- previous-arrow -->
              <button type="button">
                <img src="@/assets/icons/less-than-filled-50.png" width="30px" />
              </button>
              <!-- next-arrow -->
              <button type="button">
                <img src="@/assets/icons/more-than-filled-50.png" width="30px" />
              </button>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
      <div class="watchlist-slider-content" :id="`watchlist-carousel-${sliderTitle}`">
        <div
          v-for="(item, index) in watchlistContent"
          :key="index"
          class="categorised-watch-contents"
        >
          <div class="watchlist-box">
            <div class="card-contain">
              <categoryCard :content="item" :cardType="fetchCardType" class="card-items"></categoryCard>
            </div>
            <div class="card-summary">
              <p class="content-title">{{item.title}}</p>
            </div>
            <div class="functionality-btn">
              <img class="play-btn" src="@/assets/icons/Ic_Play1.svg" alt />
            </div>
            <img
              class="remove-btn"
              @click="removeFromWatchlist(item)"
              src="@/assets/icons/Ic_remove_mylist.svg"
              alt
            />
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { tns } from "tiny-slider/src/tiny-slider";
import { eventBus } from "@/eventBus";
import FirebaseActions from "@/mixins/FirebaseActions";

export default {
  name: "Watchlist",
  props: {
    watchContents: {
      type: Array
    }
  },
  data() {
    return {
      sliderTitle: "",
      showPlaceholder: true,
      watchlistContent: []
    };
  },
  watch: {
    watchContents() {
      // this.constructCarousel();

      this.getTitleName();
    },
    watchlistContent(val) {
      if (val.length === 0) {
        this.$emit("showPlaceholder");
      }
    }
  },
  computed: {
    fetchCardType() {
      return {
        cardName: "Slider",
        type: "LANDSCAPE",
        height: "162px",
        playButton: { enablePlay: false }
      };
    }
  },
  mounted() {
    this.constructCarousel();
  },
  created() {
    this.getTitleName();
    // console.log("props", this.watchContents);
  },
  methods: {
    getTitleName() {
      this.watchContents.findIndex(el => {
        if (el.category === "MOVIE") {
          this.sliderTitle = "MOVIE";
        } else if (el.category === "TVSHOW") {
          this.sliderTitle = "TVSHOW";
        }
      });
    },
    removeFromWatchlist(data) {
      console.log("remove", data);
      this.updateContentWatchlist(data, false);
      this.watchlistContent = this.watchlistContent.filter(el => {
        return el.objectid !== data.objectid;
      });
      if (this.watchlistContent.length == 4) {
        console.log("enetered");
        this.slider = this.slider.rebuild();
      }
      console.log("new array", this.watchlistContent);
    },
    constructCarousel() {
      if (this.watchContents.length > 0) {
        this.watchlistContent = [...this.watchContents];
      }
      if (this.watchlistContent.length === 0) return;

      let data = {
        container: `#watchlist-carousel-${this.sliderTitle}`,
        controlsContainer: `#watchlist-controls-${this.sliderTitle}`,
        nav: false,
        loop: false,
        slideBy: "page",
        gutter: 5,
        responsive: {
          576: {
            edgePadding: 5,
            gutter: 5,
            items: 1
          },
          700: {
            items: 2,
            edgePadding: 5,
            gutter: 5
          },
          900: {
            edgePadding: 5,
            gutter: 15,
            items: 4
          },
          1900: {
            edgePadding: 5,
            gutter: 5,
            items: 5
          }
        }
      };
      this.$nextTick(() => {
        this.slider = tns(data);
      });
    }
  },
  components: {
    categoryCard: () => import(/* webpackChunkName: "categoryCard" */ "@/components/Templates/categoryCard.vue")
  },
  mixins: [FirebaseActions]
};
</script>s

<style lang="scss">
@import "node_modules/tiny-slider/src/tiny-slider.scss";
.container {
  .slider-header {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    .slider-title {
      display: flex;
      .category-type {
        color: #efeff4;
        font-family: "Helvetica Neue", sans-serif;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.15px;
        line-height: 24px;
      }
      .see-all {
        cursor: pointer;
        color: #ff9600;
        font-family: "Helvetica Neue", sans-serif;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.75px;
        line-height: 27px;
        text-align: right;
        padding-left: 15px;
      }
    }
    .slider-head {
      margin-bottom: 5px;
      display: grid;
      justify-content: end;
      .slider-head-btns {
        .slider-controls {
          button {
            border: none;
            background: transparent;
            cursor: pointer;
            img {
              height: 20px;
              width: 20px;
            }
            &:disabled {
              opacity: 0.3;
            }
            &:focus {
              outline: none;
            }
          }
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
  // .tns-inner {
  //   padding: 20px 0px !important;
  //   &:first-child {
  //     padding-left: 15px !important;
  //   }
  // }
  .watchlist-slider-content {
    // position: relative;

    .watchlist-box {
      position: relative;

      &:hover {
        // -webkit-transition: all 0.4s ease-in-out;
        // -webkit-transform: scale(1.04);
        // -ms-transition: all 0.4s ease-in-out;
        // -ms-transform: scale(1.04);
        // -moz-transition: all 0.4s ease-in-out;
        // -moz-transform: scale(1.04);
        // transition: all 0.4s ease-in-out;
        // transform: scale(1.1);
        // transform: scale(1.1);
        // background: linear-gradient(0deg, rgba(0, 0, 0, 0.62), transparent);
        z-index: 1000;
        .functionality-btn {
          opacity: 1;
          cursor: pointer;
        }
        .remove-btn {
          opacity: 1;
          cursor: pointer;
        }
        .card-summary {
          opacity: 1;
        }
        .card-contain {
          filter: brightness(0.7);
        }
      }
      // &::before {
      //   position: absolute;
      //   content: "";
      //   background: linear-gradient(0deg, rgba(0, 0, 0, 0.62), transparent);
      //   left: 0;
      //   bottom: 0;
      //   height: 25%;
      //   width: 100%;
      //   z-index: 9;
      // }
      .card-summary {
        position: absolute;
        bottom: 18%;
        left: 5%;
        opacity: 0;
        // background: linear-gradient(
        //   to left,
        //   rgba(0, 0, 0, 0) 0%,
        //   rgba(0, 0, 0, 0.8) 100%
        // );
        .content-title {
          color: #ff9600;
          font-family: "Helvetica Neue", sans-serif;
          font-size: 16px;
          font-weight: bold;
        }
      }
      .functionality-btn {
        position: absolute;
        top: 0%;
        bottom: 0%;
        left: 0%;
        right: 0%;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        .play-btn {
          width: 20%;
        }
      }
      .remove-btn {
        position: absolute;
        width: 10%;
        top: 2%;
        right: 4%;
        opacity: 0;
      }
    }

    // .categorised-watch-contents{
    //   transition: transform .4s;
    //   &:hover{
    //     z-index: 1000;
    //     transform: scale(1.2);
    //   }
    // }
  }
}
</style>
